<template>
  <div>
    <new-franchise-sidebar />
    <div style="position: relative">
      <app-breadcrumb :breadcrumbTitle="false">
        <b-button
          variant="primary"
          @click="openNewFranchiseSidebar(undefined)"
          style="position: absolute; right: 13"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Nova franquia</span>
        </b-button>
      </app-breadcrumb>
    </div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Filtros -->
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative text-center lead-table"
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados leads para esta busca"
        :busy="loading"
        :primary-key="'customer_id'"
      >
        <template #cell(visible)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.visible ? "Sim" : "Não" }}
            </span>
          </div>
        </template>

        <template #cell(digital_franchise_owners)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              responsableFor(data.item)
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-wrap">
            <b-button-group>
              <b-button
                :id="`lead-customer-edit` + data.item.id"
                class="btn-icon mr-1"
                variant="flat"
                style="padding: 0"
                @click="editFranchise(data.item)"
              >
                <img
                  class="img_action"
                  src="~@/assets/images/svg/icn_edit.svg"
                />
              </b-button>
              <b-tooltip
                :target="`lead-customer-edit` + data.item.id"
                triggers="hover"
              >
                Editar
              </b-tooltip>

              <b-button
                :id="`details-bill` + data.item.id"
                class="btn-icon mx-1"
                variant="flat"
                style="padding: 0; color: white"
                @click="openFranchise(data.item)"
              >
                <feather-icon class="text-body" icon="EyeIcon" />
              </b-button>
              <b-tooltip
                :target="`details-bill` + data.item.id"
                triggers="hover"
              >
                Visualizar
              </b-tooltip>
            </b-button-group>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BCardTitle,
  BButtonGroup,
  BTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import NewFranchiseSidebar from "../components/NewFranchiseSidebar";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardText,
    BCardTitle,
    BMediaAside,
    BMediaBody,
    BButtonGroup,
    BTooltip,
    vSelect,
    NewFranchiseSidebar,
    AppBreadcrumb,
    BFormCheckbox,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 1,
      searchQuery: "",
      sortField: null,
      order: undefined,
    };
  },
  computed: {
    ...mapGetters({
      digitalFranchisesSearchResult: types.DIGITAL_FRANCHISES_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      tableColumns: types.DIGITAL_TABLE_COLUMNS,
    }),
    items: function () {
      return this.digitalFranchisesSearchResult?.data || [];
    },
    totalItems: function () {
      return this.digitalFranchisesSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.digitalFranchisesSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.digitalFranchisesSearchResult?.to || 0;
    },
  },
  async mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    await this.search();
  },
  methods: {
    ...mapActions({
      searchDigitalFranchises: types.SEARCH_DIGITAL_FRANCHISES,
      openNewFranchiseSidebar: types.OPEN_NEW_FRANCHISE_SIDEBAR,
    }),

    responsableFor(item) {
      const name = item.digital_franchise_owners.map(
        (item) => item.appify_consultant.name
      );

      for (let i = 0; i < name.length; i++) {
        const currentString = name[i];
        return currentString;
      }
    },
    search(currentPage) {
      this.loading = true;
      this.currentPage = Number.isInteger(currentPage) ? currentPage : 1;

      this.searchDigitalFranchises({
        currentPage: this.currentPage,
        limit: this.itemsPerPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as franquias. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editFranchise(item) {
      this.openNewFranchiseSidebar({
        id: item.id,
        edit: true,
        saveAction: () => {
          this.search(this.currentPage);
        },
      });
    },
    openFranchise(item) {
      this.openNewFranchiseSidebar({
        id: item.id,
        franchise: item,
        name: item.name,
        franchise_visible: item.visible,
        responsable: this.responsableFor(item),
        visualizar: true,
        saveAction: () => {
          this.search(this.currentPage);
        },
      });
    },

    clearSearch() {
      this.search(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}

input[type="datetime-local"] {
  width: 180px;
}

.lead-table .phone {
  color: #e3e3e3;
}

.dark-layout .lead-table .phone {
  color: #676d7d;
}

.lead-table .name-content {
  width: 170px;
  display: flex;
  justify-content: center;
}

.lead-table .name-content .name {
  width: auto;
}

.lead-table .name-content .name:hover {
  background-color: #f26237;
}

.lead-table .name-content .whats {
  width: 27px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.lead-table .name-content .whats:hover {
  background-color: #f26237;
}

.lead-table .btn-group .btn {
  border: unset !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

table #buttons {
  width: 40px;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.lead-table thead th {
  white-space: nowrap !important;
}
</style>
